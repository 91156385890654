import React from 'react';
import './feature.css';

const Feature = ({ skills_category, skill_1, skill_2, skill_3 }) => (
  <div className="pn__container__feature">
    <div className="pn__container__feature-title">
      <div />
      <h3>{skills_category}</h3>
    </div>
    <div className="pn__container_feature-text">
      <p>{skill_1}</p>
      <p>{skill_2}</p>
      <p>{skill_3}</p>
    </div>
  </div>
);

export default Feature;
