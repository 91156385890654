import React, {useState} from 'react';
import './timeline.css';

const Timeline = ({ timeframe, company_logo, company_name, company_address, role }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
            <div className="pn__timeline-item">
                <div className={`pn__timeline-dot ${isHovered ? 'hovered' : ''}`}></div>
                <div className={`pn__timeline-timeframe ${isHovered ? 'hovered' : ''}`}>{timeframe}</div>
                <div className="pn__timeline-content" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <h3>{company_name}, {company_address}</h3>
                        <p>{role}</p>
                </div>
            </div>
);
};

export default Timeline;