import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/pn_logo.svg';
import './header.css';
import usePopup from '../../components/popup/usePopup';
import Contact from '../../components/contact/Contact';


const Menu = () => (
  <>
  <a href="#home">Home</a>
  <a href="#skills">Skills</a>
  <a href="#experience">Work Experience</a>
  <a href="#blogs">Blogs</a>
  </>
)

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { isPopupOpen, openPopup, closePopup } = usePopup();

  return (
    <div className="pn__header">

      <div className="pn__header-links">

        <div className="pn__header-links_logo">
          <a href="#home"><img src={logo} alt="logo" /></a>
          <a href="#home"><p>Praharsh Navilae</p></a>
        </div>

        <div className="pn__navbar-links_container">
          <Menu />
        </div>

      </div>

      <div className="pn__navbar-sign">
        <button type="button" className="button__effect" onClick={openPopup}>Reach Out</button>
      </div>

      <div className="pn__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="pn__navbar-menu_container scale-up-center">
          
          <div className="pn__navbar-menu_container-links">
            <Menu />
          </div>

          <div className="pn__navbar-menu_container-links-sign">
            <button type="button" className="button__effect" id="contactForm" onClick={openPopup}>Reach Out</button>
          </div>
        </div>
        )}

      </div>
      <Contact isOpen={isPopupOpen} onClose={closePopup} />
    </div>
  );
};

export default Header;
