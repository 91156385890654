import React from 'react';
import ai from '../../assets/ai.png';
import pn_cv from '../../assets/pn_cv.pdf';
import './hero.css';
import Contact from '../../components/contact/Contact';
import usePopup from '../../components/popup/usePopup';

const Hero = () => {
  const { isPopupOpen, openPopup, closePopup } = usePopup();
  
  return (
    <div className="pn__hero section__margin "  id="home">
      
      <div className="pn__hero-content">
        <h1><span className="highlight">Hi, I am</span><br />Praharsh Navilae</h1>
        <p>Recent MBA Graduate in Aviation and Tourism Management</p>
        <p className="pn__hero-content-text-animation"></p>
        
      <div className="pn__hero-content-social-icons">
          <a href="https://www.linkedin.com/in/praharsh-navilae-633b9577/" target="_blank"><i class='bx bxl-linkedin-square'></i></a>
          <a href="https://www.xing.com/profile/PraharshNavilae_RenukaPrakash/web_profiles" target="_blank"><i class='bx bxl-xing' ></i></a>
          <a href="https://github.com/praharshnavilae" target="_blank"><i class='bx bxl-github' ></i></a>
        </div>

        <div className="pn__hero-content__button">
          <button type="button" className="button__effect" onClick={openPopup}>Let's Talk</button>
          <a href={pn_cv} download="PN_CV"><button className="button__effect" type="button">Download CV</button></a>
        </div>
      </div>

      <div className="pn__hero-image">
        <img src={ai} alt="hero" />
      </div>

      <Contact isOpen={isPopupOpen} onClose={closePopup} />
    </div>
  );
};

export default Hero;
