import React from 'react';
import Feature from '../../components/feature/Feature';
import './skills.css';

const skillsData = [
  {
    skills_category: 'Logistics and Supply Chain Management',
    skill_1: 'eFreight',
    skill_2: 'Fulfilment Digitization',
    skill_3: 'Air Freight and Global Logistics',
  },
  {
    skills_category: 'Project Management',
    skill_1: 'Project Planning and Execution',
    skill_2: 'Stakeholder Management',
    skill_3: 'Monitoring and Reporting',
  },
  {
    skills_category: 'Marketing',
    skill_1: 'Digital Marketing',
    skill_2: 'Marketing Automation',
    skill_3: 'eCRM',

  },
  {
    skills_category: 'Data Analytics',
    skill_1: 'Market Research',
    skill_2: 'Data Analysis',
    skill_3: 'Dashboard Development',
  },
  {
    skills_category: 'Event and Trade-Fair Management',
    skill_1: 'Document Handling',
    skill_2: 'Security and Operations',
    skill_3: 'Hospitality and Catering',
  },
  {
    skills_category: 'Web Development and UX',
    skill_1: 'Frontend Web Development (HTML/CSS/JS)',
    skill_2: 'Web Design',
    skill_3: 'User Experience Optimization',
  },
/*   {
    skills_category: 'Project Management',
    skill_1: 'Process Analysis and Optimization',
    skill_2: 'Project Planning',
    skill_3: 'Stakeholder Management',
  }, */
];

const Skills = () => (
  <div className="pn__skills section__margin" id="skills">
    <div className="pn__skills-container">
      <div className="pn__skills-heading">
        <h2 className="gradient__text">My Skills</h2>
      </div>
      <div className="pn__skills-content">
        {skillsData.map((item, index) => (
          <Feature skills_category={item.skills_category} skill_1={item.skill_1} skill_2={item.skill_2} skill_3={item.skill_3} key={item.skills_category + index} />
        ))}
      </div>
    </div>
  </div>
);

export default Skills;


