import React from 'react';

import './subscription.css';

const Subscription = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="pn__subscription-form" onClick={onClose}>
            <div className="pn__subscription-form-popup section__margin section__padding" onClick={onClose}>

                <div className="pn__subscription-form-content-container container__effect" onClick={(e) => e.stopPropagation()}>
                    <span className="close-subscription-form" onClick={onClose}>&times;</span>
                    
                    <form action="/submit_subscription_form" method="post">
                        
                        <h2 className="gradient__text">Subscription Form</h2>

                        <label for="fname" className="visually-hidden">First Name: </label>
                        <input type="text" id="fname" name="fname" placeholder="First Name" aria-label="First Name - Please enter your First Name" />
                        
                        <label for="lname" className="visually-hidden">Last Name: </label>
                        <input type="text" id="lname" name="lname" placeholder="Last Name" aria-label="Last Name - Please enter your Last Name" />
                        
                        <label for="email" className="visually-hidden">Email Id: </label>
                        <input type="email" id="email" name="email" placeholder="Email Id" aria-label="Email Id - Please enter your Email Id"/>
                        
                        <button type="submit" id="submit-button" className="button__effect">Submit</button>                
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Subscription;