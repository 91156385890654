import React from 'react';
import pnLogo from '../../pn_logo.svg';
import './footer.css';
import usePopup from '../../components/popup/usePopup';
import Subscription from '../../components/subscription/Subscription';

const Footer = () => {
  const { isPopupOpen, openPopup, closePopup } = usePopup();

  return (
    <div className="pn__footer">
      <div className="pn__footer-content section__margin container__effect">
        <div className="pn__footer-heading">
          <h2 className="gradient__text">Do you want to step in to the future before others</h2>
        </div>

        <div className="pn__footer-btn">
          <button type="button" id="subscriptionForm" onClick={openPopup}>Request Early Access</button>
        </div>
      </div>

      <div className="pn__footer-links section__padding">
        <div className="pn__footer-links_logo">
          <a href="#home"><img src={pnLogo} alt="pn_logo" /></a>
          <a href="#home"><p>Praharsh Navilae</p></a>
        </div>
        <div className="pn__footer-links_div">
          <h4>Links</h4>
          <a href="#"><p>Terms & Conditions </p></a>
          <a href="#"><p>Privacy Policy</p></a>
        </div>
        <div className="pn__footer-links_div">
          <h4>Contact</h4>
          <p>40589 Düsseldorf, Germany</p>
          <p>+49 17643653528</p>
          <p>praharshnavilae.rp@gmail.com</p>
        </div>
      </div>

      <div className="pn__footer-copyright">
        <p>@ 2024 Praharsh Navilae. All rights reserved.</p>
      </div>

      <Subscription isOpen={isPopupOpen} onClose={closePopup} />
    </div>
  );
};

export default Footer;
