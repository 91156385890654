import React from 'react';

import { Header, Hero, Skills, Experience, Blog, Footer  } from './containers';
import './App.css';

const App = () => (
    <div className="App">
      <div>
        <Header />
        <Hero />
      </div>
      <Skills />
      <Experience />
      <Blog />
      <Footer />
      </div>
);

export default App;
