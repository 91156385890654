import React from 'react';
import Timeline from '../../components/timeline/Timeline';
import './experience.css';

const experienceData = [
  {
    company_name: 'Lufthansa Cargo AG',
    company_address: 'Frankfurt, Germany',
    timeframe: "Aug 2023 - May 2024",
    role: 'Global eFreight Intern and Master Thesis Student',
  },
  {
    company_name: 'Accente  Gastronomie Service GmbH',
    company_address:'Frankfurt, Germany',
    timeframe: "Jan 2023 - Still Working",
    role: 'Event Security',
  },
  {
    company_name: 'Henkel AG & Co. KGaA',
    company_address: 'Düsseldorf, Germany',
    timeframe: "Apr 2021 - Sept 2021",
    role: 'Global eCRM & UX Intern',
  },
  {
    company_name: 'Yaha Foods GmbH',
    company_address: 'Frankfurt, Germany',
    timeframe: "Nov 2020 - Feb 2021",
    role: 'Delivery'
  },
  {
    company_name: 'Condor Flugdienst GmbH',
    company_address: 'Frankfurt, Germany',
    timeframe: "Mar 2020 - Jul 2020",
    role: 'Applied Research Project Student',
  },
  {
    company_name: 'Frankfurt University of Applied Sciences',
    company_address: 'Frankfurt, Germany',
    timeframe: "Sep 2019 - May 2024",
    role: 'Master of Business Administration',
  },
  {
    company_name: 'Mediacle Group',
    company_address: 'Bangalore, India',
    timeframe: "Sep 2016 - Jan 2019",
    role: 'Marketing Manager',
  },
  {
    company_name: 'Appco Group',
    company_address: 'Bangalore, India',
    timeframe:"Mar 2016 - Aug 2016",
    role: 'Business Development Associate',
  },
  {
    company_name: 'Global Academy of Technology',
    company_address: 'Bangalore, India',
    timeframe:"2012 - 2017",
    role: 'Bachelor of Engineering',
  },
  {
    company_name: 'KLE Independent Pre-University College',
    company_address: 'Bangalore, India',
    timeframe:"2010 - 2012",
    role: 'Student of Science',
  },
  {
    company_name: 'Presidency School',
    company_address: 'Bangalore, India',
    timeframe:"1998 - 2010",
    role: 'Student of Secondary Education',
  },
];

const Experience = () => (
  <div className="pn__experience section__margin" id="experience">
    <div className="pn__experience-container">
      <h2 className="gradient__text">Timeline of My Education<br />and Work Experience</h2>
      <div className="pn__timeline-container">
        <div className="pn__timeline-items">
            {experienceData.map((item, index) => (
                <Timeline company_logo={item.company_logo} company_name={item.company_name} company_address={item.company_address} timeframe={item.timeframe} role={item.role} key={item.company_name + index} />
            ))}
        </div>
      </div>
    </div>
  </div>
);

export default Experience;

